.comments-container {
  grid-area: comments;
  margin: 10px;
}

.comments-data {
  border-radius: 8px;
  box-shadow: 2px 2px 2px 5px #d7d7d7;
  margin: 0 0 10px 0;
}
.comments-header {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.comments-header h3 {
  font-size: 18px;
}

.comments-header span {
  margin-left: 30px;
  font-size: 12px;
  opacity: 0.6;
}

.comment-body {
  padding: 1rem;
}

.comment-body p {
  word-break: break-word;
  font-size: 16px;
}

.comment-body a {
  color: #6cb4ee;
}

.comment-body strong {
  font-size: 16px;
}

.comment-body li {
  margin: 20px 30px;
}

.comment-body a:hover {
  color: #6cb4ee;
  text-decoration: underline;
}

.comment-footer {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.comment-footer p {
  font-size: 14px;
  font-weight: bold;
  margin: 0 5px;
}
.view-more,
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin: 25px auto 5px;
  cursor: pointer;
  width: max-content;
}

.view-more:hover {
  text-decoration: underline;
}

@media screen and (max-width: 950px) {
  .comments-header h3 {
    font-size: 20px;
  }
  .comments-header span {
    font-size: 14px;
  }
  .comment-body p {
    font-size: 16px;
  }
  .comment-footer p {
    font-size: 14px;
  }
  #resizable-icon {
    width: 22px;
    height: 22px;
  }

  @media screen and (max-width: 500px) {
    .comments-header h3 {
      font-size: 16px;
    }
    .comments-header span {
      font-size: 12px;
    }
    .comment-body p,
    .comments-data p {
      font-size: 14px;
    }
    #resizable-icon {
      width: 20px;
      height: 20px;
    }
    .comment-footer p {
      font-size: 12px;
    }
    .view-more,
    .loading {
      font-size: 12px;
    }
  }
}
