.feed-container {
  grid-area: feed;

  position: relative;
  height: 100vh;
  margin: 0;
}

.box-container {
  min-width: 85%;
  max-width: 80%;
  height: auto;

  margin: 25px auto 30px 100px;

  border: 0 solid #000;
  background-color: #fff;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 0 17.9px rgba(0, 0, 0, 0.072), 0 1px 25px rgba(0, 0, 0, 0.086), 0 20px 80px rgba(0, 0, 0, 0.12);
}

.data-container {
  display: grid;
  height: 100%;
  grid-template-columns: 60px minmax(auto, auto);
  grid-template-rows: 50px minmax(50px, auto) 50px;
  grid-template-areas: "votes header" "votes feed" "votes footer" "votes comments";
}

.votes-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-area: votes;

  background: var(--votes-bg);
  padding-top: 20px;
}

.votes-container p {
  line-height: 2.5;
  text-align: center;
  font-weight: bold;
}

.up-vote,
.down-vote {
  cursor: pointer;
}

.up-voted {
  cursor: pointer;
  color: var(--upvote);
}

.up-voted-p {
  color: var(--upvote);
}

.down-voted {
  cursor: pointer;
  color: var(--downvote);
}

.down-voted-p {
  color: var(--downvote);
}

.feed-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;

  grid-area: header;
}

.feed-header p {
  font-size: 13px;
  padding-left: 10px;
  font-weight: bold;
  margin-right: 50px;
}

.feed-header span {
  font-size: 12px;
  opacity: 0.6;
  margin-right: 15px;
}

.feed-icon-img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-size: cover;
}

.feed-body {
  grid-area: feed;
}

.feed-body p {
  font-size: 20px;
  margin: 0 0 10px 10px;
}

.feed-body a:hover {
  text-decoration: underline;
  color: #6cb4ee;
}

.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;

  grid-area: footer;
  width: 100%;
  opacity: 0.7;
}

.comments {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 45px;
  border-radius: 4px;
}
.comments p {
  font-size: 14px;
  font-weight: bold;
  opacity: 0.65;
  padding: 0 5px;
}

.comment-icon {
  padding-left: 5px;
}

.comments:hover {
  background-color: var(--color-bg);
}

.error-media {
  opacity: 0.5;
  font-style: italic;
}

.search-no-results {
  font-weight: bold;
  max-width: 500px;
  min-width: auto;
  margin: auto;
  font-size: 20px;
  text-align: center;
  padding: 2rem;
}

.search-no-results-button {
  background-color: #000;
  color: #fff;
  border: 1.5px solid;
  border-radius: 8px;
  display: block;
  margin: auto;
  width: 150px;
  font-size: 15px;
  padding: 0.8rem;
  cursor: pointer;
}
@media screen and (max-width: 1250px) {
  .box-container {
    margin-left: 50px;
  }
}
@media screen and (max-width: 950px) {
  .box-container {
    margin-left: 0;
    max-width: 100%;
    min-width: 95%;
  }
  .comments p {
    font-size: 14px;
  }
  .feed-body p {
    font-size: 20px;
  }
  .feed-header p {
    font-size: 14px;
  }

  .feed-header span {
    font-size: 13px;
  }
  .votes-container p {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .feed-body p {
    margin-left: 5px;
    font-size: 14px;
  }
  .comments p {
    font-size: 12px;
  }
  .feed-header p {
    font-size: 10px;
    margin-right: 15px;
  }
  .feed-header {
    margin-left: 5px;
    width: 100%;
  }
  .feed-header span {
    font-size: 9px;
    margin-right: 10p;
  }
  .feed-icon-img,
  .votes-icon,
  .comment-icon {
    height: 20px;
    width: 20px;
  }

  .footer {
    padding-left: 10px;
  }
  .data-container {
    grid-template-columns: 50px minmax(auto, auto);
  }
  .votes-container p {
    font-size: 12px;
  }
}