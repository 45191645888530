.searchbar {
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;

  width: 75%;
}

.searchbar input {
  border-radius: 10px;
  width: 50%;
  min-height: 25px;

  padding: 0.75rem;
  border: solid 2px #000;
}

.search-icon {
  margin-left: 10px;
  cursor: pointer;
}

@media screen and (max-width: 520px) {
  .searchbar input {
    font-size: 12px;
  }

  .searchbar input:focus {
    font-size: 16px;
  }
  .search-icon {
    width: 15px;
    height: 15px;
  }
}