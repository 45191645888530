.top-button {
  cursor: pointer;
  position: fixed;
  right: 50px;
  bottom: 75px;
  height: 50px;
  font-size: 14px;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 950px) {
  .top-button {
    right: 25px;
  }
}