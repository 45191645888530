* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
}

a,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: inherit;
}

body {
  background: var(--color-bg);
}

.app {
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 78px auto;
  grid-template-areas: "navbar navbar" "sidebar feed";
}

@media screen and (max-width: 950px) {
  .app {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 78px auto;
    grid-template-areas: "navbar" "feed";
  }
}
