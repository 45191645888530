.skeleton {
  display: flex;
  padding-left: 1.8rem;
}

.skeleton-icon {
  margin-bottom: 2px;
}
.skeleton-text {
  margin: 0 0 2px 4px;
  width: 210px;
  height: 25px;
}

@media screen and (max-width: 980px){
  .skeleton-text {
    width: 180px;
  }
}