.comments-skeleton {
  grid-area: comments;
  margin: 10px;
}
.comments-box {
  border-radius: 8px;
  box-shadow: 2px 5px 2px 5px #e7e7e7;
  margin: 0 0 10px 0;
}

.comments-skeleton-header {
  padding: 1rem;
}

.header-span {
  width: 300px;
  height: 25px;
}

.comments-skeleton-body {
  padding: 1rem;
}

.body-span {
  width: 100%;
  height: 20px;
}


@media screen and (max-width: 500px) {
  .header-span {
    width: 150px;
  }
  .comments-skeleton {
    margin: 0;
  }
}