@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&display=swap');

:root {
    --logo-text: 'Dosis', 'sans-serif';
    --font-family: 'Roboto', 'sans-serif';
    --color-hover: #d6c7b7;
    --color-bg: #e7e7e7;
    --votes-bg: #f4f4f4;

    --upvote: #ff0000;
    --downvote: #088f8f;
}