.navbar-container {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;

  z-index: 4;
  background: #fff;
  border-bottom: 2px solid var(--color-bg);
  grid-area: navbar;
}

.navbar-header-content {
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
}

.navbar-header-content h1 {
  font-family: var(--logo-text);
  font-weight: 100;
  cursor: pointer;

  margin-right: 5rem;
}

.hamburg-menu {
  display: none;
}

.menu-open {
  position: fixed;
  top: 77px;
  right: 0;
  width: 275px;
  background-color: #fff;
  height: 100vh;
  display: none;
}

.nothing-found {
  margin: 10px;
  padding: 1.5rem;
}

#menu-open-subreddit {
  background-color: #fff;
  height: 100vh;
}

@media screen and (max-width: 950px) {
  .navbar-header-content h1 {
    margin-right: 0;
  }
  .menu-open {
    display: block;
  }
  .hamburg-menu {
    display: block;
    cursor: pointer;
  }
}

@media screen and (max-width: 710px) {
  .navbar-header-content h1 {
    font-size: 20px;
  }
  .hamburg-menu {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 520px) {
  .navbar-header-content h1 {
    font-size: 16px;
  }

  .navbar-header-content {
    padding: 1rem;
  }
  .hamburg-menu {
    width: 25px;
    height: 25px;
  }
  .menu-open {
    top: 75px;
  }
}
